// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.sign_title{
    font-size: 18px;
    font-weight: 500;
    color: var(--color1);
}
.sign_google img{
width: 20px;
}
.sign_google{
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: var(--color1);
    font-weight: 500;
    box-shadow: inset 0 0 0 2px #e3e9ed;
    padding: 14px 0px;
    border-radius: 6px;
}
.sign_form label{
    font-size: 16px;
    font-weight: 500;
}
.text_link{
    color: #077CD0;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
}
.signin_btn, .signin_btn:hover{
    background-color: #077CD0;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding: 14px 0px !important;
}

@media screen and (min-width:768px) {
    .sign_container{
        width: 30%;
        margin: 0 auto;
    }
}

@media screen and (max-width:767px) {
    .sign_container{
        width: 80%;
        margin: 0 auto;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/SignIn/style.css"],"names":[],"mappings":";AACA;IACI,eAAe;IACf,gBAAgB;IAChB,oBAAoB;AACxB;AACA;AACA,WAAW;AACX;AACA;IACI,QAAQ;IACR,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,oBAAoB;IACpB,gBAAgB;IAChB,mCAAmC;IACnC,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,qBAAqB;IACrB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,yBAAyB;IACzB,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,4BAA4B;AAChC;;AAEA;IACI;QACI,UAAU;QACV,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,UAAU;QACV,cAAc;IAClB;AACJ","sourcesContent":["\n.sign_title{\n    font-size: 18px;\n    font-weight: 500;\n    color: var(--color1);\n}\n.sign_google img{\nwidth: 20px;\n}\n.sign_google{\n    gap: 8px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 16px;\n    color: var(--color1);\n    font-weight: 500;\n    box-shadow: inset 0 0 0 2px #e3e9ed;\n    padding: 14px 0px;\n    border-radius: 6px;\n}\n.sign_form label{\n    font-size: 16px;\n    font-weight: 500;\n}\n.text_link{\n    color: #077CD0;\n    text-decoration: none;\n    font-size: 16px;\n    font-weight: 500;\n}\n.signin_btn, .signin_btn:hover{\n    background-color: #077CD0;\n    color: #fff;\n    font-size: 16px;\n    font-weight: 500;\n    padding: 14px 0px !important;\n}\n\n@media screen and (min-width:768px) {\n    .sign_container{\n        width: 30%;\n        margin: 0 auto;\n    }\n}\n\n@media screen and (max-width:767px) {\n    .sign_container{\n        width: 80%;\n        margin: 0 auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
