import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dashboard from './pages/Dashboard';
import MainLayout from './components/layout/MainLayout';
import AuthLayout from './components/layout/AuthLayout';
function App() {
  return (
    <div className="App">
      <div>
        <Routes>
          <Route path="/*" element={<AuthLayout />} />
          <Route path="/app/*" element={<MainLayout />} />
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </div>
    </div>
  );
}

export default App;
