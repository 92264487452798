// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --color1: #07344C;
}

body {
  font-family: "IBM Plex Sans", sans-serif !important;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.container_wrapper .sidenav {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 48px;
  background-color:#032B59;
}
.main-layout{
  padding-left: 48px;
  /* background: #F9F9FA; */
  background-color: #F4F4FC;
  min-height: 695px;
}
.top_header-title{
  font-size: 22px;
  color: #161616;
}
.top_header-title span{
  font-weight: 500;
}
.page_title-path{
  text-transform: capitalize;
}
.top_header-title{
  box-shadow:0 0.8px 1.8px rgba(0, 0, 0, 0.13), 0 0.15px 0.45px rgba(0, 0, 0, 0.11);
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAGA;EACE,iBAAiB;AACnB;;AAEA;EACE,mDAAmD;AACrD;;;;AAIA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF;;;AAGA;EACE,eAAe;EACf,OAAO;EACP,MAAM;EACN,aAAa;EACb,WAAW;EACX,wBAAwB;AAC1B;AACA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,yBAAyB;EACzB,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,cAAc;AAChB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,iFAAiF;AACnF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');\n\n\n:root {\n  --color1: #07344C;\n}\n\nbody {\n  font-family: \"IBM Plex Sans\", sans-serif !important;\n}\n\n\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n\n.container_wrapper .sidenav {\n  position: fixed;\n  left: 0;\n  top: 0;\n  height: 100vh;\n  width: 48px;\n  background-color:#032B59;\n}\n.main-layout{\n  padding-left: 48px;\n  /* background: #F9F9FA; */\n  background-color: #F4F4FC;\n  min-height: 695px;\n}\n.top_header-title{\n  font-size: 22px;\n  color: #161616;\n}\n.top_header-title span{\n  font-weight: 500;\n}\n.page_title-path{\n  text-transform: capitalize;\n}\n.top_header-title{\n  box-shadow:0 0.8px 1.8px rgba(0, 0, 0, 0.13), 0 0.15px 0.45px rgba(0, 0, 0, 0.11);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
