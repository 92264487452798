// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account_holder{
    width: 30px;
    height: 30px;
    display: inline-flex;
    background: #783cce;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    color: #fff;
}
.account_holder-name{
    text-decoration: unset;
    color: #161616;
}
.table_search{
    width: 230px;
    /* float: right; */
}
.custom-table .ant-table-thead > tr > th {
    background-color: #EDEEF0; 
    color: #161616; 
    padding: 6px 16px;
  }
  .social_media{

  }
  .social_media ul{
    display: flex;
    list-style-type: none;
    gap: 10px;
    align-items: center;
  }
  .social_media ul li img{
    width: 34px;
    height: 20px;
    object-fit: contain;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Accounts/accounts.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,oBAAoB;IACpB,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;AACf;AACA;IACI,sBAAsB;IACtB,cAAc;AAClB;AACA;IACI,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,cAAc;IACd,iBAAiB;EACnB;EACA;;EAEA;EACA;IACE,aAAa;IACb,qBAAqB;IACrB,SAAS;IACT,mBAAmB;EACrB;EACA;IACE,WAAW;IACX,YAAY;IACZ,mBAAmB;EACrB","sourcesContent":[".account_holder{\n    width: 30px;\n    height: 30px;\n    display: inline-flex;\n    background: #783cce;\n    border-radius: 50px;\n    align-items: center;\n    justify-content: center;\n    color: #fff;\n}\n.account_holder-name{\n    text-decoration: unset;\n    color: #161616;\n}\n.table_search{\n    width: 230px;\n    /* float: right; */\n}\n.custom-table .ant-table-thead > tr > th {\n    background-color: #EDEEF0; \n    color: #161616; \n    padding: 6px 16px;\n  }\n  .social_media{\n\n  }\n  .social_media ul{\n    display: flex;\n    list-style-type: none;\n    gap: 10px;\n    align-items: center;\n  }\n  .social_media ul li img{\n    width: 34px;\n    height: 20px;\n    object-fit: contain;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
